export { default as newTab } from "./images/new-tab.svg";
export { default as twitter } from "./images/twitter.svg";
export { default as linkedin } from "./images/linkedin.svg";
export { default as facebook } from "./images/facebook.svg";
export { default as instagram } from "./images/instagram.svg";
export { default as copyBold } from "./images/copy-bold.svg";
export { default as whatsapp } from "./images/whatsapp.svg";
export { default as close } from "./images/close.png";
export { default as closes } from "./images/close.svg";
export { default as heart } from "./images/heart.svg";
export { default as mail } from "./images/mail.svg";
export { default as password } from "./images/password.svg";
export { default as notification } from "./images/notifications.svg";
export { default as profileAvatar } from "./images/profileAvatar.webp";
export { default as image3 } from "./images/website-image.jpg";
export { default as eyeIcon } from "./images/eyeIcon.svg";
export { default as saveIcon } from "./images/Tags-favorite.svg";
export { default as favoriteHeart } from "./images/fev-heart.svg";
export { default as menu } from "./images/menu.svg";
export { default as verified } from "./images/verified.svg";
export { default as review } from "./images/review.svg";
export { default as upload } from "./images/Upload.svg";
export { default as discount } from "./images/discount.svg";
export { default as fail } from "./images/failed.svg";
export { default as success } from "./images/success.svg";
export { default as reject } from "./images/rejected.svg";
export { default as imageIcon } from "./images/image-icon.png";
export { default as saveCollection } from "./images/save-collection.svg";
export { default as searchIcon } from "./images/search icon.svg";
export { default as newTabIcon } from "./images/newTab.svg";
export { default as banner } from "./images/banner.svg";
export { default as roundedText } from "./images/Round Text.svg";
export { default as likeIcon } from "./images/likes Icon gray.svg";
export { default as viewIcon } from "./images/views icon gray.svg";
export { default as backArrow } from "./images/back_arrow.svg";
export { default as authImage } from "./animateImage/SignIn-SignUp.json";
export { default as forgotPasswordImage } from "./animateImage/forgot-password.json";
export { default as confirmPassword } from "./images/confirmPassword.svg";
export { default as google } from "./images/google.svg";
export { default as pageNotFound } from "./animateImage/404.json";
export { default as noDataFound } from "./animateImage/no-data-found.json";
export { default as newsCircle } from "./animateImage/news_circle.png";
export { default as noNotification } from "./images/noNotification.svg";
export { default as likeCelebration } from "./animateImage/like_celebration.gif";
export { default as searchWebsite } from "./animateImage/search_final.json";
export { default as copy } from "./images/copy.png";
export { default as uxVortex } from "./images/uxVortex_final.png";
export { default as fontLoader } from "./animateImage/loader.json";
export { default as navMenu } from "./images/nav-menu.svg";
export { default as popularIcon } from "./images/Popular-icon.svg";
export { default as countryIcon } from "./images/country-icon.svg";
export { default as typographyIcon } from "./images/typography-icon.svg";
export { default as whiteLogo } from "./images/uxVortex_logo_white.png";
export { default as aboutUs } from "./images/aboutUs.png";
export { default as successImage } from "./animateImage/successImage.json";
export { default as warningImage } from "./animateImage/warning.json";
export { default as emailSendImage } from "./animateImage/emailSend.json";
export { default as aboutUsIcon } from "./images/aboutUsIcon.png";
export { default as submitWebsite } from "./images/submitWebsite.png";
export { default as webSnapshot } from "./images/webSnapshot.png";
export { default as upArrow } from "./animateImage/upArrow.json";
export { default as aboutUsAnimation } from "./animateImage/about-us.json";
export { default as UploadImage } from "./images/Upload_image.svg";
export { default as imageScreenshot } from "./images/submit_screenshot_image.png";
export { default as leftArrow } from "./images/left-arrow.png";
export { default as uploadSuccessfully } from "./images/uploadSuccessfully.svg";
export { default as homeBackground } from "./images/home-background.svg";
export { default as profileIcons } from "./images/profile.png";
export { default as profileBackground } from "./images/profile-background.jpg";
export { default as profileIcon } from "./images/profile-icon.png";
export { default as collectionIcon } from "./images/collection-icon.png";
export { default as settingIcon } from "./images/setting-icon.png";
export { default as logOutIcon } from "./images/logout-icon.png";
export { default as post } from "./images/post.svg";
export { default as twitterProfile } from "./images/twitter-profile.svg";
export { default as faceBookProfile } from "./images/facebook-profile.svg";
export { default as figmaProfile } from "./images/figma-profile.svg";
export { default as instagramProfile } from "./images/insta-profile.svg";
export { default as gitProfile } from "./images/git-profile.svg";
export { default as userProfile } from "./images/user-profile.svg";
export { default as eMailIcon } from "./images/e-mail-icon.svg";
export { default as confirmPasswordIcon } from "./images/Confirm-Password.svg";
export { default as newPasswordIcon } from "./images/New Password.svg";
export { default as sessionWindow } from "./images/session-window.svg";
export { default as currentSession } from "./images/currentSession.svg";
export { default as InactiveSession } from "./images/InactiveSession.svg";
export { default as sessionIos } from "./images/session-ios.svg";
export { default as sessionLinux } from "./images/session-linux.svg";
export { default as deleteWebIcon } from "./images/delete-web-icon.png";
export { default as editWebIcon } from "./images/edit-web-icon.png";
export { default as userNameIcon } from "./images/user-name-icon.png";
export { default as portfolioLocation } from "./images/location.svg";
export { default as portfolioProfile } from "./images/portfolio-profile.svg";

